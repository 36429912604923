import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

const Contact = () => {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="70vh">
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Box mb={4}>
                            <Typography variant="h4" gutterBottom>Contact Us</Typography>
                            <Typography variant="body1">
                                Have questions or feedback? Reach out to us! We're here to ensure you have the best experience exploring our AI-generated city guides. Your input helps us continuously improve our services for you. Thank you for joining us on this journey of discovering new places effortlessly.
                            </Typography>
                        </Box>
                        {/* Social Media Links */}
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <IconButton component={Link} href="#" target="_blank" rel="noopener">
                                <FacebookIcon />
                            </IconButton>
                            <IconButton component={Link} href="#" target="_blank" rel="noopener">
                                <TwitterIcon />
                            </IconButton>
                            <IconButton component={Link} href="#" target="_blank" rel="noopener">
                                <InstagramIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Name"
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Email"
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Message"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <button className="custom-btn btn-15 w-full">
                                        Send Message
                                    </button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Contact;
