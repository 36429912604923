import React from 'react';
import { Box, Typography, Button, useTheme } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const ContactInfo = () => {
    const theme = useTheme();
    const backgroundColor = theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200];
    const textColor = theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                p: 4,
                backgroundColor: backgroundColor,
                borderRadius: '8px',
                mt: 4,
                mb: 10,
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Adding shadow for depth
            }}
        >
            <Typography variant="h4" gutterBottom sx={{ color: textColor }}>
                Get in Touch
            </Typography>
            <Typography variant="body1" sx={{ mb: 3, color: textColor }}>
                We would love to hear from you! Reach out to us through any of the following ways:
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
                <Button startIcon={<PhoneIcon />} color="primary" onClick={() => window.location.href = 'tel:+1234567890'}>
                    +1 (234) 567-890
                </Button>
                <Button startIcon={<EmailIcon />} color="primary" onClick={() => window.location.href = 'mailto:info@example.com'}>
                    info@example.com
                </Button>
                <Button startIcon={<LocationOnIcon />} color="primary">
                    123 Main Street, Anytown, USA
                </Button>
            </Box>
        </Box>
    );
}

export default ContactInfo;
