import React from 'react';
import { Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <Container>
            <Typography variant="h2" component="h1" gutterBottom>
                404 - Page Not Found
            </Typography>
            <Typography variant="body1" gutterBottom>
                Sorry, the page you are looking for does not exist.
            </Typography>
            <Typography variant="body1">
                Go back to the <Link to="/">Home Page</Link>.
            </Typography>
        </Container>
    );
};

export default NotFound;
