import axios from 'axios';
import { API_BASE_URL, USER_TOKEN } from './api/apiConfig';

const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
    headers: {
        common: {
            "Accept": "application/json",
        }
    }
});

axiosInstance.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem(USER_TOKEN);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
