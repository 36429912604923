import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Typography, Paper, Grid } from "@mui/material";
import cityGuideImage from '../assets/images/city-guide.png';
import countriesImage from '../assets/images/countries.png';
import citiesImage from '../assets/images/city.png';
import sidebarImage from '../assets/images/sidebar.png';
import darkMapImage from '../assets/images/dark-map.png';
import mobMapImage from '../assets/images/mob-map.png';
import mobSiteImage from '../assets/images/mob-site.png';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import MapIcon from '@mui/icons-material/Map';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import { styled } from '@mui/material/styles';
import '../styles/Features.css';

gsap.registerPlugin(ScrollTrigger);

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    textAlign: 'center',
    minHeight: '300px',
}));

const Features: React.FC = () => {
    const imageContainerRef = useRef<HTMLDivElement>(null);
    const imagesRef = useRef<HTMLDivElement[]>([]);

    useEffect(() => {
        const container = imageContainerRef.current;
        const images = imagesRef.current;

        const isDesktop = window.innerWidth > 768;

        if (isDesktop && container && images.length) {
            gsap.to(images[1], {
                scrollTrigger: {
                    trigger: images[0],
                    start: 'top bottom',
                    end: 'center center',
                    scrub: true,
                },
                opacity: 1,
                x: 0,
            });
            gsap.to(images[2], {
                scrollTrigger: {
                    trigger: images[1],
                    start: 'bottom bottom',
                    end: () => `+=${0.5 * window.innerHeight}`,
                    scrub: true,
                },
                opacity: 1,
                x: 0,
            });
            gsap.to(images[3], {
                scrollTrigger: {
                    trigger: images[1],
                    start: 'bottom bottom',
                    end: () => `+=${0.5 * window.innerHeight}`,
                    scrub: true,
                },
                opacity: 1,
                x: 0,
            });
            gsap.to(images[4], {
                scrollTrigger: {
                    trigger: images[3],
                    start: 'bottom bottom',
                    end: () => `+=${window.innerHeight}`,
                    scrub: true,
                },
                opacity: 1,
                x: 0,
            });
            gsap.to(images[5], {
                scrollTrigger: {
                    trigger: images[4],
                    start: 'top center',
                    end: () => `+=${0.1 * window.innerHeight}`,
                    scrub: true,
                },
                opacity: 1,
                x: 0,
            });
            gsap.to(images[6], {
                scrollTrigger: {
                    trigger: images[4],
                    start: 'top center',
                    end: () => `+=${0.7 * window.innerHeight}`,
                    scrub: true,
                },
                opacity: 1,
                x: 0,
            });
        } else {
            images.forEach((image) => {
                image.style.position = 'relative';
                image.style.transform = 'none';
                image.style.opacity = '1';
            });
        }
    }, []);

    return (
        <>
            <Typography variant="h4" gutterBottom sx={{marginBottom: '30px'}}>
                Discover the Key Highlights of Our App
            </Typography>
            <Grid container spacing={2} sx={{marginBottom: '50px'}}>
                <Grid item xs={12} md={3}>
                    <Item elevation={3} square={false}>
                        <NotListedLocationIcon sx={{ fontSize: 60, marginBottom: '30px' }} />
                        <Typography variant="h5" gutterBottom>
                            Detailed Information
                        </Typography>
                        <Typography gutterBottom>
                            Access comprehensive details on each point of interest, including historical facts, visitor
                            tips, operating hours, and contact information.
                        </Typography>
                    </Item>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Item elevation={3} square={false}>
                        <AutoFixHighIcon sx={{ fontSize: 60, marginBottom: '30px' }} />
                        <Typography variant="h5" gutterBottom>
                            Customizable Itineraries
                        </Typography>
                        <Typography gutterBottom>
                            Create and save personalized itineraries to make the most of your trip, depending on your
                            your available time and interests.
                        </Typography>
                    </Item>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Item elevation={3} square={false}>
                        <MapIcon sx={{ fontSize: 60, marginBottom: '30px' }} />
                        <Typography variant="h5" gutterBottom>
                            Open on Google Maps
                        </Typography>
                        <Typography gutterBottom>
                            Open all the points of interest on Google Maps to get directions and see the location on the map.
                        </Typography>
                    </Item>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Item elevation={3} square={false}>
                        <FilterListOffIcon sx={{ fontSize: 60, marginBottom: '30px' }} />
                        <Typography variant="h5" gutterBottom>
                            Offline Access
                        </Typography>
                        <Typography gutterBottom>
                            Download city guides as a pdf file for offline use, ensuring you have access to essential
                            information without needing an internet connection.
                        </Typography>
                    </Item>
                </Grid>
            </Grid>
            <div ref={imageContainerRef} style={{ position: 'relative', height: '2000px', marginBottom: '150px', overflowX: 'hidden' }}>
                <div ref={(el) => imagesRef.current[0] = el!} style={{position: 'relative'}}>
                    <img src={cityGuideImage} alt="App"/>
                </div>
                <div ref={(el) => imagesRef.current[1] = el!}
                     className="feature-image"
                     style={{
                         position: 'absolute',
                         top: '3%',
                         left: '40px',
                         opacity: 0,
                         maxWidth: '300px',
                         transform: 'translateX(-100%)'
                     }}>
                    <Typography variant="h5" gutterBottom className="feature-label">
                        Select Country
                    </Typography>
                    <img src={countriesImage} alt="Select Country" />
                </div>
                <div ref={(el) => imagesRef.current[2] = el!}
                     className="feature-image"
                     style={{
                         position: 'absolute',
                         top: '8%',
                         left: '250px',
                         opacity: 0,
                         maxWidth: '300px',
                         transform: 'translateX(-100%)'
                     }}>
                    <Typography variant="h5" gutterBottom className="feature-label">
                        Select City
                    </Typography>
                    <img src={citiesImage} alt="Select City" />
                </div>
                <div ref={(el) => imagesRef.current[3] = el!}
                     className="feature-image"
                     style={{
                         position: 'absolute',
                         top: '3%',
                         right: 0,
                         opacity: 0,
                         maxWidth: '300px',
                         transform: 'translateX(100%)'
                     }}>
                    <Typography variant="h5" gutterBottom className="feature-label">
                        Customize your guide
                    </Typography>
                    <img src={sidebarImage} alt="Sidebar" />
                </div>
                <div ref={(el) => imagesRef.current[4] = el!}
                     className="feature-image"
                     style={{
                         position: 'absolute',
                         top: '39%',
                         right: 0,
                         opacity: 0,
                         transform: 'translateX(100%)'
                     }}>
                    <Typography variant="h5" gutterBottom className="feature-label">
                        Open your guide in google maps
                    </Typography>
                    <img src={darkMapImage} alt="Map" />
                </div>
                <div ref={(el) => imagesRef.current[5] = el!}
                     className="feature-image"
                     style={{
                         position: 'absolute',
                         top: '80%',
                         right: '300px',
                         opacity: 0,
                         transform: 'translateX(100%)'
                     }}>
                    <img src={mobSiteImage} alt="Mobile Site" />
                </div>
                <div ref={(el) => imagesRef.current[6] = el!}
                     className="feature-image"
                     style={{
                         position: 'absolute',
                         top: '82%',
                         right: '50px',
                         opacity: 0,
                         transform: 'translateX(100%)'
                     }}>
                    <img src={mobMapImage} alt="Mobile google map" />
                </div>
            </div>
        </>
    );
};

export default Features;
