import React from 'react';
import '../styles/HypedBox.css';
import { useSelector } from 'react-redux';
import { selectSiteName } from '../store/selectors';

const HypedBox = () => {
    const siteName = useSelector(selectSiteName);
    return (
        <div className="hyped-box-container">
            <div className="card">
                <div className="card-content">
                    <h3>About the app</h3>
                    <h1>Welcome to the {siteName}!</h1>
                    <p>Make exploring new cities easy and enjoyable. With our app, you can discover top attractions,
                        hidden gems, and local favorites, all curated to provide the best experience for travelers and locals alike.
                        The intuitive interface allows you to effortlessly navigate through various sections, learn about each feature, and get detailed
                        information on points of interest. Whether you're planning a trip or just curious about what's around you,
                        the {siteName} App is your perfect companion for uncovering the best a city has to offer.</p>
                </div>
            </div>
        </div>
    );
};

export default HypedBox;
