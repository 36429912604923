import React, {useEffect, useState} from 'react';
import { Container, Box, Typography, Button, Grid, Card, CardContent, TextField, Avatar } from '@mui/material';
import {useSelector} from "react-redux";
import {RootState} from "../store";

const UserProfile: React.FC = () => {
    const user = useSelector((state: RootState) => state.user);
    const [userInfo, setUserInfo] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
    });

    useEffect(() => {
        if (user) {
           setUserInfo({
               ...userInfo,
               name: user.name,
               email: user.email,
           });
        }
    },[user]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUserInfo({
            ...userInfo,
            [name]: value,
        });
    };

    const handleSaveChanges = () => {
        console.log(userInfo);
    };
    return (
        <Container maxWidth="md" className="pt-6">
            <Box className="text-center mb-6">
                <Typography variant="h4">Edit Profile</Typography>
            </Box>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12}>
                    <Card>
                        <CardContent>
                            <Box component="form" noValidate autoComplete="off">
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Name"
                                            name="name"
                                            value={userInfo.name}
                                            variant="outlined"
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Email"
                                            name="email"
                                            value={userInfo.email}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Password"
                                            name="password"
                                            type="password"
                                            value={userInfo.password}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Confirm Password"
                                            name="confirmPassword"
                                            type="password"
                                            value={userInfo.confirmPassword}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="text-center">
                                        <button onClick={handleSaveChanges} className="custom-btn btn-15 mt-10 mb-10">
                                            Save Changes
                                        </button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default UserProfile;
