import * as React from 'react';

import Box from '@mui/material/Box';
import BraintreeDropIn from '../pricing/BraintreeDropIn';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Info from './Info';
import InfoMobile from './InfoMobile';
import PaymentForm from './PaymentForm';

export default function Checkout() {
  return (
      <Grid container sx={{ height: { xs: '100%', sm: '100dvh' } }}>
          <Grid
              item
              xs={12}
              sm={5}
              lg={4}
              sx={{
                  display: { xs: 'none', md: 'flex' },
                  flexDirection: 'column',
                  borderRight: { sm: 'none', md: '1px solid' },
                  borderColor: { sm: 'none', md: 'divider' },
                  alignItems: 'start',
                  pt: 4,
                  px: 10,
                  gap: 4,
              }}
          >
              <Box
                  sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                      width: '100%',
                      maxWidth: 500,
                  }}
              >
                  <Info totalPrice='$134.98' />
              </Box>
          </Grid>
          <Grid
              item
              sm={12}
              md={7}
              lg={8}
              sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '100%',
                  width: '100%',
                  alignItems: 'start',
                  pt: { xs: 2, sm: 4 },
                  px: { xs: 2, sm: 10 },
                  gap: { xs: 4, md: 8 },
              }}
          >
              <Card
                  sx={{
                      display: { xs: 'flex', md: 'none' },
                      width: '100%',
                  }}
              >
                  <CardContent
                      sx={{
                          display: 'flex',
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          ':last-child': { pb: 2 },
                      }}
                  >
                      <div>
                          <Typography variant="subtitle2" gutterBottom>
                              Selected products
                          </Typography>
                          <Typography variant="body1">
                              {'$144.97'}
                          </Typography>
                      </div>
                      <InfoMobile totalPrice={'$144.97'} />
                  </CardContent>
              </Card>
              <Box
                  sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                      width: '100%',
                      maxWidth: { sm: '100%', md: 600 },
                      maxHeight: '720px',
                      gap: { xs: 5, md: 'none' },
                  }}
              >
                  <PaymentForm />
                  ###<BraintreeDropIn />###
              </Box>
          </Grid>
      </Grid>
  );
}
