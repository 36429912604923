import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import { Brightness4, Brightness7 } from '@mui/icons-material';
import { toggleTheme } from '../store/actions';
import { RootState } from '../store';

const ThemeToggle = () => {
    const dispatch = useDispatch();
    const themeMode = useSelector((state: RootState) => state.themeMode);

    return (
        <IconButton onClick={() => dispatch(toggleTheme())} color="inherit">
            {themeMode === 'light' ? <Brightness4 /> : <Brightness7 />}
        </IconButton>
    );
};

export default ThemeToggle;
