import React, {useEffect, useState} from 'react';
import MarkersList from '../components/MarkersList';
import MapView  from "../components/MapView";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store";
import Typography from "@mui/material/Typography";
import { useParams } from 'react-router-dom';
import { constructGoogleMapsUrl } from '../helpers';
import { guide } from '../api/apiService';
import {Action, Dispatch} from "redux";
import { useTheme, useMediaQuery } from '@mui/material';
import {showSnackbar} from "../store/actions";

const Guide: React.FC = () => {
    const { id } = useParams();
    const dispatch = useDispatch<Dispatch<Action>>();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [position, setPosition] = useState([0, 0]);
    const [zoom, setZoom] = useState(3);
    const [showMap, setShowMap] = useState(true);
    const [title, setTitle] = useState<any>('');
    const [googleMapsUrl, setGoogleMapsUrl] = useState<any>("");
    const [guideData, setGuideData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchGuide = async () => {
            try {
                const data = await guide(id || 0);
                if (data) {
                    setGuideData(data);
                    setTitle(`${data.city}, ${data.country}`);
                    setGoogleMapsUrl(constructGoogleMapsUrl(data.markers, `${data.city}, ${data.country}`));
                    dispatch({ type: 'SET_MARKERS', payload: data.markers });
                }
            } catch (error: any) {
                dispatch(showSnackbar(error.message, 'error'));
            } finally {
                setLoading(false);
            }
        };
        fetchGuide().then();
    }, [id]);

    const themeMode = useSelector((state: RootState) => state.themeMode);
    const bgSidebar = themeMode === 'dark' ? 'sidebar-dark-bg' : 'bg-gray-100';

    const toggleMapList = () => {
        setShowMap(!showMap);
    }
    return (
        <div className="mb-12">
            <Typography
                component="h3"
                variant={isMobile ? 'h4' : 'h3'}
                align="center"
                gutterBottom
                className="mb-5"
            >
                { guideData || loading ? title : "Guide not found" }
            </Typography>
            {guideData && (<div className="flex flex-col md:flex-row gap-4">
                <div className={`sidebar flex-none md:max-w-sm w-full md:block`}>
                    <div className={bgSidebar + " p-2 md:p-6 max-w-4xl mx-auto rounded-lg h-full"}>
                        <div className="actions flex flex-row justify-start gap-4 mb-2 mt-2 md:mt-6">
                            {googleMapsUrl && (
                                <div className="mb-4 md:mb-12 flex justify-between w-full">
                                    <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer"
                                       className="flex gap-4 font-bold items-center rounded-lg bg-white border border-black text-black p-4">
                                        <span>Open on</span>
                                        <img src={`/google_maps.png`} alt="Google map link" className="w-32 h-7 md:w-38"/>
                                    </a>
                                    <div title={!showMap ? "Show map" : "Show list"}
                                         className={"flex gap-4 font-bold items-center rounded-lg bg-white border border-black text-black p-4 " + (!showMap ? "show-map" : "show-list")}
                                         onClick={() => toggleMapList()}/>
                                </div>
                            )}
                        </div>
                        <div>
                            <Typography
                                component="h5"
                                variant="h5"
                                align="left"
                                gutterBottom
                            >
                                Time to visit: {guideData.day_period}
                            </Typography>
                            <Typography
                                component="h5"
                                variant="h5"
                                align="left"
                                gutterBottom
                            >
                                Duration: {guideData.duration} hours
                            </Typography>
                            {guideData.attractions?.length && (<Typography
                                component="h5"
                                variant="h5"
                                align="left"
                                gutterBottom
                            >
                                Attractions: {guideData.attractions.map((item: any) => item.name).join(",")}
                            </Typography>)}
                            {guideData.transports?.length > 0 && (<Typography
                                component="h5"
                                variant="h5"
                                align="left"
                                gutterBottom
                            >
                                Transportation: {guideData.transports.map((item: any) => item.name).join(",")}
                            </Typography>)}
                            <Typography
                                component="h5"
                                variant="h5"
                                align="left"
                                gutterBottom
                            >
                                {guideData.payment_preference === "Both" ? "Interested in paid and free activities" : `Payment Preference: ${guideData.payment_preference}`}
                            </Typography>
                            <Typography
                                component="h5"
                                variant="h5"
                                align="left"
                                gutterBottom
                            >
                                Physical Activity: {guideData.physical_activity}
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className="main-content flex-grow rounded-lg">
                    {showMap && (
                        <MapView position={position} zoom={zoom}/>
                    )}
                    {!showMap && (
                        <MarkersList googleLink={googleMapsUrl} place={title} />
                    )}
                </div>
            </div>)}
        </div>
    );
};

export default Guide;
