import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './store';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { RootState } from './store';
import { PaletteMode } from '@mui/material';
import SnackbarAlert from './components/SnackbarAlert';
import { configureStoreHelper } from './helpers';

configureStoreHelper(store);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const AppWrapper = () => {
    const themeMode: PaletteMode = useSelector((state: RootState) => state.themeMode);
    const theme = createTheme({
        palette: {
            mode: themeMode,
            primary: {
                light: '#f3f3f3',
                main: '#f1f1f1',
                dark: '#002884',
                contrastText: '#002884',
            },
            background: {
                default: themeMode === 'light' ? '#ffffff' : '#0a1929',
                paper: themeMode === 'light' ? '#ffffff' : '#0a1929',
            },
            text: {
                primary: themeMode === 'light' ? '#000000' : '#ffffff',
                secondary: themeMode === 'light' ? '#000000' : '#ffffff',
            }
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarAlert />
            <App />
        </ThemeProvider>
    );
};

root.render(
    <Provider store={store}>
        <Router>
            <AppWrapper />
        </Router>
    </Provider>
);
