import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Form from '../components/Form';
import MarkersList from '../components/MarkersList';
import MapView  from "../components/MapView";
import { useSelector } from "react-redux";
import {RootState} from "../store";
import Typography from "@mui/material/Typography";
import { constructGoogleMapsUrl } from "../helpers";
import { useMediaQuery, useTheme } from "@mui/material";
import "../styles/CreateGuide.css";
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';

const CreateGuide: React.FC = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [position, setPosition] = useState([0, 0]);
    const [zoom, setZoom] = useState(3);
    const [loading, setLoading] = useState(false);
    const [showMap, setShowMap] = useState(true);
    const [showSidebar, setShowSidebar] = useState(true);
    const [place, setPlace] = useState<any>("");

    const markers = useSelector((state: RootState) => state.markers);

    const themeMode = useSelector((state: RootState) => state.themeMode);
    const bgSidebar = themeMode === 'dark' ? 'sidebar-dark-bg' : 'bg-gray-100';

    const googleMapsUrl = constructGoogleMapsUrl(markers, place);

    const onSubmit = (data: any) => {
        setShowSidebar(false);
    };

    const toggleMapList = () => {
        setShowMap(!showMap);
        setShowSidebar(false);
    }

    useEffect(() => {
        dispatch({ type: 'SET_MARKERS', payload: [] });
    }, [dispatch]);
    return (
        <>
            <Typography
                component="h4"
                variant={isMobile ? 'h5' : 'h4'}
                align="center"
                gutterBottom
                className="!mb-6 md:!mb-9"
            >
                Create Your Custom City Guide
            </Typography>
            <Typography variant="body1" align="center" color="text.secondary" component="p" className="!mb-6 hidden md:block">
                Customize your city adventure with tailored options and let our AI generate a personalized itinerary. Discover the best attractions, complete with details on prices and travel times, all displayed on an interactive map. Enjoy seamless navigation and make the most of your journey!
            </Typography>
            {googleMapsUrl && (
                <div className="actions flex md:justify-end justify-between gap-4 mb-2 mt-2">
                    <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer"
                       className="flex gap-4 font-bold items-center rounded-lg bg-white border border-black text-black p-4">
                        <span>Open on</span>
                        <img src={`/google_maps.png`} alt="Google map link" className="w-32 h-7 md:w-38"/>
                    </a>
                    <div title={!showMap ? "Show map" : "Show list"}
                         className={"flex gap-4 font-bold items-center rounded-lg bg-white border border-black text-black p-4 " + (!showMap ? "show-map" : "show-list")}
                         onClick={() => toggleMapList()}/>
                </div>
            )}
            <div className="w-full overflow-hidden">
                <div className={`create-guide-container flex flex-col md:flex-row gap-0 md:gap-4 relative ${showSidebar ? 'inputs' : 'map'}`}>
                    <div
                        className={`sidebar flex-none md:max-w-sm w-full`}>
                        <div className={bgSidebar + " p-3 md:p-6 max-w-4xl mx-auto rounded-lg h-full"}>
                            <Form
                                onSubmit={onSubmit}
                                loading={loading}
                                setLoading={setLoading}
                                setPosition={setPosition}
                                setZoom={setZoom}
                                setPlace={setPlace}
                            />
                        </div>
                    </div>
                    <div className="main-content flex-grow rounded-lg">
                        {showMap && (
                            <MapView position={position} zoom={zoom}/>
                        )}
                        {!showMap && (
                            <MarkersList googleLink={googleMapsUrl} place={place}/>
                        )}
                    </div>
                </div>
            </div>
            <button
                className="fixed bottom-[3px] right-[3px] bg-purple-300 w-14 h-14 text-black text-sm font-semibold py-1 px-2 rounded-full md:hidden z-[9999]"
                onClick={() => setShowSidebar(!showSidebar)}
            >
                {showSidebar ? <MapOutlinedIcon /> : <BorderColorOutlinedIcon /> }
            </button>
        </>
    );
};

export default CreateGuide;
