import React, { useEffect, useRef } from 'react';
import dropin from 'braintree-web-drop-in';
import axios from 'axios';

const BraintreeDropIn: React.FC = () => {
    const dropinContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const initializeDropin = async () => {
            try {
                const response = await axios.get('http://localhost/your-path/braintree_server.php?action=client_token');
                const clientToken = response.data.clientToken;

                dropin.create({
                    authorization: clientToken,
                    container: dropinContainer.current || "",
                    paypal: {
                        flow: 'vault'
                    },
                    googlePay: {
                        googlePayVersion: 2,
                        merchantId: 'your-google-pay-merchant-id',
                        transactionInfo: {
                            totalPriceStatus: 'FINAL',
                            totalPrice: '10.00',
                            currencyCode: 'USD'
                        },
                    },
                    card: {
                        // Optionally, specify a selector for the card container
                        // container: '#card-container'
                    }
                }, (error, instance) => {
                    if (error) {
                        console.error(error);
                        return;
                    }
                    const btn = document.getElementById('submit-button');
                    if (btn && instance) {
                        btn.addEventListener('click', () => {
                            instance.requestPaymentMethod((err, payload) => {
                                if (err) {
                                    console.error(err);
                                    return;
                                }

                                axios.post('http://localhost/your-path/braintree_server.php?action=checkout', {
                                    paymentMethodNonce: payload.nonce,
                                    amount: '10.00' // Replace with actual amount
                                }).then(response => {
                                    if (response.data.success) {
                                        alert('Payment successful!');
                                    } else {
                                        alert('Payment failed: ' + response.data.error);
                                    }
                                }).catch(err => {
                                    console.error(err);
                                });
                            });
                        });
                    }
                });
            } catch (error) {
                console.error('Error fetching client token:', error);
            }
        };

        initializeDropin();
    }, []);

    return (
        <div>
            <div ref={dropinContainer}></div>
            <button id="submit-button">Pay Now</button>
        </div>
    );
};

export default BraintreeDropIn;
