import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import '../styles/PublicGuides.css';
import { Country } from 'country-state-city';
import FlagIcon from '../components/FlagIcon';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Link from '@mui/material/Link';
import { Guide } from '../store/types';
import { guides } from '../api/apiService';
import { useMediaQuery, useTheme } from "@mui/material";

const PublicGuides = () => {
    const [guidesData, setGuidesData] = useState<Guide[]>([]);
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState('');
    const itemsPerPage = 3;
    const allCountries = Country.getAllCountries();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const flags = allCountries
        .filter(({name}) => guidesData.some((guide) => guide.country === name))
        .reduce((acc: any, { name, isoCode }) => {
            acc[name] = isoCode;
            return acc;
        }, {});

    useEffect(() => {
        guides(page, true, filters).then(res => {
            if (res?.data?.length) {
                setGuidesData(res.data);
            }
        });
    }, [page, filters]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value.toLowerCase();
        setPage(1);
        setFilters( `country=${searchTerm}&city=${searchTerm}`);
    };

    const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage);
    };

    return (
        <>

            <Typography component="h4" variant={isMobile ? 'h5' : 'h4'} align="center" gutterBottom>Public Guides</Typography>
            <Typography variant="body1" align="center" gutterBottom>
                Explore city guides created by users around the world. Filter by city or country.
            </Typography>
            <Box mt={4} mb={6} display="flex" justifyContent="center" sx={{maxWidth: '350px', mx: 'auto'}}>
                <TextField
                    label="Search City or Country"
                    variant="standard"
                    onChange={handleSearchChange}
                    fullWidth
                    data-lpignore="true"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Grid container spacing={3}>
                {guidesData.map((guide) => (
                    <Grid item xs={12} sm={6} md={4} key={guide.id}>
                        <Link href={`/guide/${guide.id}`} color="inherit" underline="none">
                            <Box
                                className="guide-box"
                                style={{
                                    backgroundImage: `url(https://picsum.photos/800/600?random=${guide.id})`,
                                }}
                            >
                                {flags[guide.country] && <div className="flag-icon"><FlagIcon isoCode={flags[guide.country]} width={20} imageWidth={32} /></div>}
                                <Typography variant="h6" className="city-name">
                                    {guide.city}, {guide.country}
                                </Typography>
                                {/*<Box className="avatar-container" title={`by ${guide.user.name}`}>*/}
                                {/*    <Avatar alt={guide.user.name} src={guide.user.avatar} className="user-avatar" />*/}
                                {/*</Box>*/}
                            </Box>
                        </Link>
                    </Grid>
                ))}
            </Grid>
            <Box mt={4} display="flex" justifyContent="center">
                <Pagination
                    count={Math.ceil(guidesData.length / itemsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    color="primary"
                />
            </Box>
        </>
    );
};

export default PublicGuides;
