import React from 'react';
import { Grid, Typography } from '@mui/material';
import '../styles/Home.css';
import { useNavigate } from 'react-router-dom';

const Hero = () => {
    const navigate = useNavigate();

    const handleButton = () => {
        navigate('/user/create-guide');
    };

    return (
        <div className="hero-container mt-12 mb-6">
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <div className="hero-message">
                        <Typography variant="h3" component="h2" gutterBottom>
                            Discover the best places around the city with
                            <span className="magic">
                                <span className="magic-text"> AI-generated</span>
                            </span>
                            guides, in seconds.
                        </Typography>
                        <button onClick={handleButton} className="custom-btn btn-15 mt-10 mb-10">Get Started</button>
                    </div>
                </Grid>
                <Grid item xs={12} md={8}>
                    <div className="hero-images">
                        <div className="parallax-image" style={{backgroundImage: 'url(/images/sidebar.png)'}}/>
                        <div className="parallax-image" style={{backgroundImage: 'url(/images/map.png)'}}/>
                        <div className="parallax-image" style={{backgroundImage: 'url(/images/popup.png)'}}/>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default Hero;
