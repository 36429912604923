import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import PeopleIcon from '@mui/icons-material/People';
import ExploreIcon from '@mui/icons-material/Explore';

const AboutUs = () => {
    // Example statistics
    const citiesCovered = 1000;
    const happyUsers = 50000;

    return (
        <Container maxWidth="md">
            <Typography variant="h4" align="center" gutterBottom>About Us</Typography>
            <Typography variant="body1" align="justify">
                Welcome to [Your App Name], where we combine cutting-edge technology with the joy of exploration. Our mission is to revolutionize travel experiences by providing AI-generated city guides tailored to your interests. Whether you're a seasoned traveler or embarking on your first adventure, our guides offer curated recommendations and insights to enrich your journey.
                <br/><br/>
                At [Your App Name], we are driven by our passion for discovery and our commitment to making travel accessible to everyone. With over {citiesCovered} cities covered and {happyUsers} happy users worldwide, we strive to deliver guides that are informative, reliable, and inspiring.
                <br/><br/>
                Join us in exploring the world's wonders effortlessly, guided by the power of AI and the expertise of our dedicated team.
            </Typography>

            {/* Statistics Section */}
            <Box mt={4} mb={2}>
                <Divider />
            </Box>
            <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} sm={4} md={3}>
                    <Box textAlign="center">
                        <ExploreIcon style={{ fontSize: 60, color: '#f44336' }} />
                        <Typography variant="h6" gutterBottom>Locations Explored</Typography>
                        <Typography variant="h4">{citiesCovered}</Typography>
                        <Typography variant="body2">cities worldwide</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <Box textAlign="center">
                        <PeopleIcon style={{ fontSize: 60, color: '#2196f3' }} />
                        <Typography variant="h6" gutterBottom>Happy Travelers</Typography>
                        <Typography variant="h4">{happyUsers}</Typography>
                        <Typography variant="body2">satisfied users</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <Box textAlign="center">
                        <LocalAirportIcon style={{ fontSize: 60, color: '#4caf50' }} />
                        <Typography variant="h6" gutterBottom>Guides Created</Typography>
                        <Typography variant="h4">500+</Typography>
                        <Typography variant="body2">curated guides</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Box mt={4}>
                <Divider />
            </Box>
        </Container>
    );
};

export default AboutUs;
