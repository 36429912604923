import React from 'react';
import { Box, Typography } from '@mui/material';
import '../styles/Footer.css';
import { useSelector } from 'react-redux';
import { selectSiteName } from '../store/selectors';

const Footer = () => {
    const year = new Date().getFullYear();
    const siteName = useSelector(selectSiteName);
    return (
        <Box
            component="footer"
            sx={{
                py: 0,
                px: 0,
                mt: 'auto',
                backgroundColor: 'transparent',
                position: 'relative',
                height: '300px',
            }}
        >
            <Typography variant="body2" color="textSecondary" className="bottom-text">
                ©{year} {siteName}. All rights reserved.
            </Typography>
            <span style={{display: 'none'}}>
                Credits for waves: https://codepen.io/goodkatz/pen/LYPGxQz
            </span>
            <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                 viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                <defs>
                    <path id="gentle-wave"
                          d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"/>
                </defs>
                <g className="parallax">
                    <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"/>
                    <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"/>
                    <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"/>
                    <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff"/>
                </g>
            </svg>
        </Box>
    );
};

export default Footer;
