import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FlagIcon from './FlagIcon';

type Option = {
    label: string;
    value: string;
};

type ComboBoxProps = {
    id: string;
    label: string;
    value: Option;
    onChange: any;
    options: any;
    showFlags?: boolean;
};

const ComboBox: React.FC<ComboBoxProps> = ({id, label, value, onChange, options, showFlags}) => {
    const [open, setOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);

    const handleOpen = () => {
        setFilteredOptions(options.slice(0, 100));
        setOpen(true);
        setTimeout(() => {
            setFilteredOptions(options);
        }, 1);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <div>
            <FormControl fullWidth>
                <Autocomplete
                    disablePortal
                    id={`autocomplete-${id}`}
                    options={filteredOptions}
                    fullWidth
                    value={value.label || null}
                    open={open}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    onChange={(event: any, newValue: string | null) => {
                        onChange(newValue);
                    }}
                    isOptionEqualToValue={(option: any, value) => {
                        return option.label === value
                    }}
                    onInputChange={(event, newInputValue) => {
                        setFilteredOptions(options.filter((option: Option) =>
                            option.label.toLowerCase().includes(newInputValue.toLowerCase())
                        ));
                    }}
                    renderOption={(props: any, option: any) => {
                        const { key, ...restProps } = props;
                        return (
                        <Box key={key} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...restProps}>
                            {showFlags && (
                                <FlagIcon isoCode={option.value} width={20} imageWidth={20} />
                            )}
                            {option.label}
                        </Box>
                    )}}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label={label}
                        />}
                />
            </FormControl>
        </div>
    );
}

export default ComboBox;
