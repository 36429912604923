export type ThemeMode = 'light' | 'dark';
export const TOGGLE_THEME = 'TOGGLE_THEME';
export const SET_SITE = 'SET_SITE';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const SET_MARKERS = 'SET_MARKERS';
export const SET_TOKENS = 'SET_TOKENS';
export const SET_FORM_DATA = 'SET_FORM_DATA';
export const SET_USER = 'SET_USER';
export const SET_PUBLIC_GUIDES = 'SET_PUBLIC_GUIDES';
export const SAVE_USER_LOCALLY = 'SAVE_USER_LOCALLY';
export const LOGOUT = 'LOGOUT';
export const SET_PLACE = 'SET_PLACE';
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const SET_LOADING = 'SET_LOADING';

export interface FormProps {
    onSubmit: (data: any) => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    setPosition: (position: [number, number]) => void;
    setZoom: (zoom: number) => void;
    setPlace: any;
}

export interface RequestGuideData {
    country: string;
    city?: string;
    duration: any;
    attractions: string[];
    day_period: string;
    transports: string[];
    payment_preference: string;
    physical_activity: string;
    public: number;
}

export interface Guide {
    attractions: object[];
    city: string;
    country: string;
    day_period: string;
    duration: number;
    id: number;
    markers: object[];
    payment_preference: string;
    physical_activity: string;
    public: boolean;
    transports: object[];
    created_date?: string;
    image?: string;
    iso?: string;
}

export interface User {
    email: string;
    name: string;
    id: string;
    authToken: string;
    role: string;
    tokens: number;
    last_guides: Guide[];
}

export interface SnackbarState {
    open: boolean;
    message: string;
    severity: 'success' | 'info' | 'warning' | 'error';
}

export interface State {
    openMenu: boolean;
    markers: any[];
    formData: any;
    user: User | null;
    publicGuides: any[];
    place: string;
    themeMode: ThemeMode;
    site: any;
    snackbar: SnackbarState,
    loading: boolean,
}

export interface Action {
    type: string;
    payload?: any;
}

export const initialState: State = {
    openMenu: false,
    markers: [],
    formData: null,
    user: null,
    publicGuides: [],
    place: '',
    themeMode: 'dark',
    site: { name: 'Ulysses', description: '', url: '' },
    snackbar: { open: false, message: '', severity: "info" },
    loading: true,
};
