import {logout as logoutAction, setUser} from '../store/actions';
import {USER, USER_TOKEN} from "../api/apiConfig";
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import { Guide } from '../store/types';
import { Country } from 'country-state-city';

let storeInstance: any;

export const configureStoreHelper = (store: any) => {
    storeInstance = store;
};

export const dispatchAction = (action: any) => {
    if (!storeInstance) {
        throw new Error('Store instance is not configured. Call configureStoreHelper first.');
    }
    storeInstance.dispatch(action);
};

export const setUserAction = (user: any) => {
    dispatchAction(setUser(user));
};

export const getUserGuides = () => {
    let userGuides = storeInstance.getState().user.last_guides;
    userGuides = userGuides.map((guide: Guide) => {
        if (!guide['image']) {
            guide['image'] = `https://picsum.photos/800/600?random=${guide.id}`
        }
        if (!guide['iso']) {
            const country = Country.getAllCountries()
                .filter(item => item.name === guide.country )
                .map(({ name, isoCode }) => ({ iso: isoCode }));
            guide['iso'] = country.length ? country[0].iso : ''
        }
        if (!guide['created_date']) {
            guide['created_date'] = "2024-05-05";
        }
        return guide;
    })
    return userGuides;
}

export const getUserTokens = () => {
    return storeInstance.getState().user.tokens;
}

export const isAuthenticated = () => {
   return storeInstance.getState().user != null;
};

export const useHandleLogout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return () => {
        localStorage.removeItem(USER);
        localStorage.removeItem(USER_TOKEN);
        dispatch(logoutAction());
        navigate('/login');
    };
};

export const getBrowserName = () => {
    const userAgent = navigator.userAgent;
    let browserName;

    if (/MSIE|Trident/.test(userAgent)) {
        browserName = 'explorer';
    } else if (/Edg/.test(userAgent)) {
        browserName = 'edge';
    } else if (/Chrome/.test(userAgent) && /Google Inc/.test(navigator.vendor)) {
        browserName = 'chrome';
    } else if (/Safari/.test(userAgent) && /Apple Computer/.test(navigator.vendor)) {
        browserName = 'safari';
    } else if (/Firefox/.test(userAgent)) {
        browserName = 'firefox';
    } else if (/Opera|OPR/.test(userAgent)) {
        browserName = 'opera';
    } else {
        browserName = 'Unknown';
    }

    return browserName;
}

export const formatDate = (dateString: string | undefined) => {

    const date = new Date(dateString || "");
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear().toString().slice(-2);

    const daySuffix = (day: number) => {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    return `${day}${daySuffix(day)} of ${month} ${year}'`;
}

export const constructGoogleMapsUrl = (markers: any[], place: string) => {
    if (markers?.length === 0) return "";

    const baseUrl = "https://www.google.com/maps/dir/?api=1&travelmode=walking&dir_action=navigate";
    const origin = `${markers[0].name},${place}`;
    const destination = `${markers[markers.length - 1].name},${place}`;
    const waypoints = markers.slice(1, -1)
        .map(marker => `${marker.name},${place}`)
        .join('|');

    return waypoints
        ? `${baseUrl}&origin=${origin}&destination=${destination}&waypoints=${waypoints}`
        : `${baseUrl}&origin=${origin}&destination=${destination}`;
};

export const generateGoogleMapsLink = (placeName: string) => {
    const baseURL = 'https://www.google.com/maps/search/?api=1&query=';
    const encodedPlaceName = encodeURIComponent(placeName);
    return `${baseURL}${encodedPlaceName}`;
}
