import React from 'react';
import Hero from '../components/Hero';
import Features from '../components/Features';
import HowItWorks from '../components/HowItWorks';
import CallToAction from '../components/CallToAction';
import ContactInfo from '../components/ContactInfo';

import '../styles/Home.css';

const Home = () => {
    return (
        <div className="home-container">
            <Hero />
            <HowItWorks />
            <Features />
            <CallToAction />
            <ContactInfo />
        </div>
    );
};

export default Home;
