import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import Badge from '@mui/material/Badge';
import { useNavigate } from 'react-router-dom';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PublicIcon from '@mui/icons-material/Public';
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';
import { useHandleLogout } from '../helpers';
import { RootState } from '../store';
import { useSelector } from 'react-redux';

export default function AccountMenu() {
    const tokens = useSelector((state: RootState) => state.user?.tokens || 0);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleLogout = useHandleLogout();

    const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
        setAnchorEl(event.currentTarget as unknown as HTMLElement);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuItemClick = (path: string) => {
        navigate(path);
        handleClose();
    };

    return (
        <React.Fragment>
            <AddCircleOutlineOutlinedIcon fontSize="large" className="cursor-pointer mr-4" titleAccess="Create a new guide" onClick={() => handleMenuItemClick('/user/create-guide/')} />
            <Badge badgeContent={tokens} color="primary" className="mr-6" title={`${tokens} Available Tokens`}>
                <TokenOutlinedIcon fontSize="large" className="cursor-pointer" onClick={() => handleMenuItemClick('/user/buy-tokens//')}  />
            </Badge>
            <PersonPinCircleOutlinedIcon fontSize="large" className="cursor-pointer" onClick={handleClick} />
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => handleMenuItemClick('/user/profile/')}>
                    <ListItemIcon>
                        <AccountBoxIcon fontSize="small" />
                    </ListItemIcon>
                    Profile
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('/user/guides/')}>
                    <ListItemIcon>
                        <PublicIcon fontSize="small" />
                    </ListItemIcon>
                    My Guides
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('/user/buy-tokens/')}>
                    <ListItemIcon>
                        <TokenOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    Buy Tokens
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}
