import React,{ useEffect, useState, useRef }  from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import '../styles/AnimatedBackground.css';

const AnimatedBackground = () => {
    const themeMode = useSelector((state: RootState) => state.themeMode);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const requestRef = useRef<number>();

    const updatePosition = (x: number, y: number) => {
        setPosition({ x, y });
    };

    const handleMouseMove = (event: MouseEvent) => {
        const { clientX, clientY } = event;

        if (requestRef.current) {
            cancelAnimationFrame(requestRef.current);
        }

        requestRef.current = requestAnimationFrame(() => updatePosition(clientX, clientY));
    };

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            if (requestRef.current) {
                cancelAnimationFrame(requestRef.current);
            }
        };
    }, []);

    return (
        <div
            className={`animated-background ${themeMode}`}
            style={{
                    background: `radial-gradient(circle at ${position.x -100}px ${position.y + 100}px, rgb(111 29 111 / 30%), rgb(23 66 23 / 30%))`,
            }}
        />
    );
};

export default AnimatedBackground;
