import React from 'react';

interface FlagIconProps {
    isoCode: string;
    width: number;
    imageWidth?: number;
    className?: string;
}

const FlagIcon: React.FC<FlagIconProps> = ({ isoCode, width, imageWidth, className }) => {
    return (
        <img
            loading="lazy"
            width={imageWidth}
            srcSet={`https://flagcdn.com/w${width * 2}/${isoCode.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w${width}/${isoCode.toLowerCase()}.png`}
            alt={`${isoCode} flag`}
            className={className}
        />
    );
};

export default FlagIcon;
