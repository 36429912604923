import React from 'react';
import { Box } from '@mui/material';
import HypedBox from "./HypedBox";

const HowItWorks = () => {
    return (
        <Box className="how-it-works-section" sx={{ my: 15, textAlign: 'center' }}>
            <HypedBox />
        </Box>
    );
};

export default HowItWorks;
