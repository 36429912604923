export const BASE_URL = 'https://city.tediousapp.xyz';
export const API_BASE_URL = BASE_URL + '/api';
export const USER_TOKEN = 'userToken';
export const USER = 'user';

export const endpoints = {
    register: '/register',
    login: '/login',
    update: '/info',
    user: '/user',
    options: '/options',
    guides: '/guides',
    publicGuides: '/guides/public',
};
