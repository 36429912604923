import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

type SelectFieldProps = {
    id: string;
    label: string;
    value: any;
    onChange: any;
    options: any;
};

const SelectField: React.FC<SelectFieldProps> = ({
                                                     id, label, value, onChange, options
                                                 }) => {
    const handleChange = (event: SelectChangeEvent) => {
        onChange(event.target.value as string);
    };
    return (
        <FormControl fullWidth>
            <InputLabel id={`label-${id}`}>{label}</InputLabel>
            <Select
                labelId={`label-${id}`}
                id={`select-${id}`}
                value={value}
                label={label}
                onChange={handleChange}
            >
                {
                    options.map((option: any, index: number) => (
                        <MenuItem key={index} value={option}>{option}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );
}

export default SelectField;
