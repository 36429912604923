import React, { useEffect } from 'react';
import './App.css';
import AppRoutes from './AppRoutes';
import {Container } from '@mui/material';
import Header from './components/Header';
import AnimatedBackground from './components/AnimatedBackground';
import Footer from './components/Footer';
import {useSelector} from "react-redux";
import {RootState} from "./store";
import { useDispatch } from 'react-redux';
import {USER, USER_TOKEN} from "./api/apiConfig";
import { user } from "./api/apiService";
import {setLoading, setUser} from "./store/actions";

function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        const userToken = localStorage.getItem(USER_TOKEN);
        if (userToken) {
           dispatch(setLoading(true));
           user()
               .then((res: any) => {
                   dispatch(setUser(res));
                })
               .catch(error => {
                   localStorage.setItem(USER, "");
                   localStorage.setItem(USER_TOKEN, "");
               })
               .finally(()=>{
                   dispatch(setLoading(false));
               });
        } else {
            dispatch(setLoading(false));
        }
    }, [dispatch]);

    const themeMode = useSelector((state: RootState) => state.themeMode);
    return (
        <div className={"App " + themeMode}>
            <AnimatedBackground />
            <Header />
            <Container maxWidth="xl">
                <AppRoutes />
            </Container>
            <Footer />
        </div>
    );
}

export default App;
