import React, { useState } from 'react';
import { Container, Box, Typography, TextField, Grid, Card, CardContent, CardMedia } from '@mui/material';
import FlagIcon from '../components/FlagIcon';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { formatDate, getUserGuides } from "../helpers";
import { Guide } from '../store/types';
import Link from '@mui/material/Link';

const UserGuides: React.FC = () => {
    const [filter, setFilter] = useState('');
    const guides = getUserGuides();

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.value);
    };

    const filteredGuides = guides.filter((guide: Guide) =>
        guide.city.toLowerCase().includes(filter.toLowerCase()) ||
        guide.country.toLowerCase().includes(filter.toLowerCase())
    );

    // Group guides by country
    const groupedGuides = filteredGuides.reduce((groups: any, guide: Guide) => {
        if (!groups[guide.country]) {
            groups[guide.country] = [];
        }
        groups[guide.country].push(guide);
        return groups;
    }, {} as { [key: string]: typeof guides });


    return (
        <Container maxWidth="lg" className="pt-6">
            <Box className="text-center mb-6">
                <Typography variant="h4">Saved Guides</Typography>
            </Box>
            <div style={{
                marginTop: '16px',
                marginBottom: '24px',
                display: 'flex',
                justifyContent: 'center',
                maxWidth: '350px',
                margin: 'auto'
            }}>
                <TextField
                    label="Filter by City or Country"
                    variant="standard"
                    value={filter}
                    onChange={handleFilterChange}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon/>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            {/* Guides List */}
            {Object.keys(groupedGuides).map(country => (
                <Box key={country} className="mb-6">
                    <div className="flex mb-6 gap-4">
                        <FlagIcon isoCode={groupedGuides[country][0].iso} width={40}
                                  className="rounded-full object-cover w-10 h-10 border-2 border-white"/>
                        <Typography variant="h5" className="mb-4 ml-4 leading-10">{country}</Typography>
                    </div>
                    <Grid container spacing={4}>
                        {groupedGuides[country].map((guide: Guide) => (
                            <Grid item xs={12} sm={6} md={4} key={guide.id}>
                                <Link href={`/guide/${guide.id}`} color="inherit" underline="none">
                                    <Card>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image={guide.image}
                                            alt={`${guide.city} image`}
                                        />
                                        <CardContent>
                                            <Box className="flex justify-between items-center mb-4">
                                                <Typography variant="h6">{guide.city}</Typography>
                                                <Typography variant="body2"
                                                            color="textSecondary">{formatDate(guide.created_date)}</Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Link>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            ))}
        </Container>
    );
};

export default UserGuides;
