import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { RootState } from '../store';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box'
import { isAuthenticated } from '../helpers';

interface PrivateRouteProps {
    component: React.ComponentType;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component }) => {
    const loading = useSelector((state: RootState) => state.loading);
    const location = useLocation();

    if (loading) {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        )
    }

    return isAuthenticated() ? <Component /> : <Navigate to="/login" state={{ from: location }} />;
};

export default PrivateRoute;
