import React from 'react';
import { Box, Typography, Button, useTheme } from '@mui/material';

const CallToAction = () => {
    const theme = useTheme();
    const backgroundColor = theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200];
    const textColor = theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                p: 4,
                backgroundColor: backgroundColor,
                borderRadius: '8px',
                mt: 10,
                mb: 10,
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Typography variant="h4" gutterBottom sx={{ color: textColor }}>
                Join Us Today!
            </Typography>
            <Typography variant="body1" sx={{ mb: 3, color: textColor }}>
                Sign up now to start exploring new cities and uncover hidden gems. Download our app for the best experience.
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
                <Button variant="contained" color="primary" size="large" onClick={() => console.log('Sign Up Clicked')}>
                    Sign Up
                </Button>
                <Button variant="outlined" color="primary" size="large" onClick={() => console.log('Download App Clicked')}>
                    Download App
                </Button>
            </Box>
        </Box>
    );
}

export default CallToAction;
