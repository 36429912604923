import * as React from 'react';
import {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../store/actions';
import { register } from '../../api/apiService';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { getBrowserName } from '../../helpers';
import { useNavigate, useLocation } from 'react-router-dom';

export default function SignUp() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    useEffect(() => {
        if (code) {
            console.log('in')
            localStorage.setItem('code', code);
        }
    }, [code]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        terms: false,
        token_name: '',
    });


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = event.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (formData.password !== formData.password_confirmation) {
            dispatch(showSnackbar('Passwords do not match', 'error'));
            return;
        }

        try {
            const code = localStorage.getItem('code');
            await register({
                name: formData.name,
                email: formData.email,
                password: formData.password,
                password_confirmation: formData.password_confirmation,
                terms: formData.terms ? 1 : 0,
                token_name: getBrowserName(),
                coupon: code
            }, navigate);

            dispatch(showSnackbar('You have registered successfuly', 'success'));
            localStorage.removeItem('code');
        } catch (error: any) {
            dispatch(showSnackbar(error.response.data.message, 'error'));
        }
    };

    return (
        <Card sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '655px',
            mx: 'auto',
            pa: '20px',
        }}>
            <CardContent>
                <Typography component="h1" variant="h4">
                    Register
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 3}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="name"
                                required
                                fullWidth
                                id="name"
                                label="Name"
                                autoFocus
                                value={formData.name}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                value={formData.password}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                name="password_confirmation"
                                label="Confirm Password"
                                type="password"
                                id="password_confirmation"
                                autoComplete="new-password"
                                value={formData.password_confirmation}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.terms}
                                        onChange={handleInputChange}
                                        name="terms"
                                        color="primary"
                                    />
                                }
                                label="I agree to the terms and conditions"
                            />
                        </Grid>
                    </Grid>
                    <button
                        type="submit"
                        className="custom-btn btn-15 mt-4 mb-4 w-6/12"
                    >
                        Sign Up
                    </button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="/login" color="inherit" underline="none">
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    );
}
