import {
    State, Action, initialState,
    TOGGLE_THEME,
    SET_SITE,
    TOGGLE_MENU,
    SET_MARKERS,
    SET_FORM_DATA,
    SET_USER,
    SET_PUBLIC_GUIDES,
    SAVE_USER_LOCALLY,
    LOGOUT,
    SET_PLACE,
    SHOW_SNACKBAR,
    CLOSE_SNACKBAR,
    SET_LOADING,
    SET_TOKENS
} from './types';

const reducer = (state = initialState, action: Action): State => {
    switch (action.type) {
        case TOGGLE_MENU:
            return { ...state, openMenu: !state.openMenu };
        case SET_MARKERS:
            return { ...state, markers: action.payload };
        case SET_TOKENS:
            if (state.user) {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        tokens: action.payload,
                    },
                };
            }
            return state;
        case SET_FORM_DATA:
            return { ...state, formData: action.payload };
        case SET_PUBLIC_GUIDES:
            return { ...state, publicGuides: action.payload };
        case SAVE_USER_LOCALLY:
            return { ...state, user: action.payload };
        case SET_PLACE:
            return { ...state, place: action.payload };
        case TOGGLE_THEME:
            return {
                ...state,
                themeMode: state.themeMode === 'light' ? 'dark' : 'light',
            };
        case SET_SITE:
            return {
                ...state,
                site: state.site
            };
        case SHOW_SNACKBAR:
            return {
                ...state,
                snackbar: {
                    open: true,
                    message: action.payload.message,
                    severity: action.payload.severity,
                },
            };
        case CLOSE_SNACKBAR:
            return {
                ...state,
                snackbar: {
                    ...state.snackbar,
                    open: false,
                },
            };
        case SET_USER:
            return {
                ...state,
                user: action.payload,
                loading: false, // Set loading to false once user is set
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case LOGOUT:
            return {
                ...state,
                user: null,
            };
        default:
            return state;
    }
};

export default reducer;
