import { TOGGLE_THEME, SHOW_SNACKBAR, CLOSE_SNACKBAR, SET_USER, SET_LOADING, LOGOUT, User } from './types';

export const logout = () => ({
    type: LOGOUT,
});

export const toggleTheme = () => ({
    type: TOGGLE_THEME,
});

export const showSnackbar = (message: string, severity: 'success' | 'info' | 'warning' | 'error'): any => ({
    type: SHOW_SNACKBAR,
    payload: { message, severity },
});

export const closeSnackbar = (): any => ({
    type: CLOSE_SNACKBAR,
});

export const setUser = (user: User) => ({
    type: SET_USER,
    payload: user,
});

export const setLoading = (loading: boolean) => ({
    type: SET_LOADING,
    payload: loading,
});
