import axiosInstance from '../axiosInstance';
import { endpoints } from './apiConfig';
import { registerType, loginType } from './types';
import { USER_TOKEN, USER } from './apiConfig';
import { setUserAction } from '../helpers';

const saveUser = async (response: any, navigate: any) => {
    localStorage.setItem(USER, JSON.stringify(response.data.user));
    localStorage.setItem(USER_TOKEN, response.data.token);
    setUserAction(response.data.user);
    navigate('/user/create-guide/');
}

export const register = async (userData: registerType, navigate: any) => {
    try {
        const response = await axiosInstance.post(endpoints.register, userData);
        await saveUser(response, navigate);
    } catch (error) {
        console.error('Error registering user:', error);
        throw error;
    }
};

export const login = async (credentials: loginType, navigate: any) => {
    try {
        const response = await axiosInstance.post(endpoints.login, credentials);
        await saveUser(response, navigate);
    } catch (error) {
        console.error('Error logging in:', error);
        throw error;
    }
};

export const user = async () => {
    try {
        const res = await axiosInstance.get(endpoints.user);
        if (res.status === 200) {
            return res.data;
        }
    } catch (error) {
        console.error('Error logging in:', error);
        throw error;
    }
};

export const update = async (userData: registerType, id: number | string, navigate: any) => {
    try {
        const response = await axiosInstance.put(`${endpoints.update}/?id=${id}}`, userData);
        console.log(response);
    } catch (error) {
        console.error('Error logging in:', error);
        throw error;
    }
};

export const guide = async (id: number | string) => {
    try {
        const res = await axiosInstance.get(`${endpoints.guides}/${id}`);
        if (res.status === 200) {
            return res.data;
        }
        return null
    } catch (error) {
        console.error('Error logging in:', error);
        throw error;
    }
};

export const createGuide = async (data: object) => {
    try {
        const res = await axiosInstance.post(endpoints.guides, data);
        if (res.status === 200 || res.status === 201) {
            return res.data;
        }
        return null
    } catch (error) {
        console.error('Error logging in:', error);
        throw error;
    }
};

export const options = async () => {
    try {
        const res = await axiosInstance.get(endpoints.options);
        if (res.status === 200) {
            return res.data;
        }
        return null
    } catch (error) {
        console.error('Error logging in:', error);
        throw error;
    }
};

export const guides = async (page: number = 1, isPublic: boolean = true, filters: any = '') => {
    try {
        const url = isPublic ? endpoints.publicGuides : endpoints.guides;
        const filtersParam = filters ? `&${filters}` : '';
        const res = await axiosInstance.get(`${url}?page=${page}${filtersParam}`);
        if (res.status === 200) {
            return res.data;
        }
        return null
    } catch (error) {
        console.error('Error logging in:', error);
        throw error;
    }
};
